<template>
  <div class="balance-detial-container">
    <van-tabs
      class="van-tabs-fixed"
      sticky
      color="#90D12E"
      title-active-color="#90D12E"
      v-model="formPage.keyword"
      @change="handleChange"
    >
      <van-tab name="-1" :title="$t('全部')" />
      <van-tab name="1" :title="$t('收入')" />
      <van-tab name="0" :title="$t('支出')" />
    </van-tabs>
    <div class="balancedetial-list" ref="container">
      <div v-for="(item, index) in balanceList" :key="index">
        <div class="balancedetial-date">
          {{ item.mouth }}
        </div>
        <div class="balancedetial-item">
          <div
            class="balance-item"
            v-for="(items, index) in item.list"
            :key="index"
          >
            <div>
              <div class="balance-type">{{ items.type }}</div>
              <div class="balance-date">{{ items.createTime }}</div>
            </div>
            <div
              :style="items.price > 0 ? 'color: #F82207;' : ''"
              class="amount"
            >
              {{ items.price > 0 ? "+" + items.price : items.price }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-loading v-if="isMore" size="13px" class="loading" vertical
      >{{ $t("加载中") }}...</van-loading
    >
    <div v-else class="more">{{ $t("沒有更多啦") }}~</div>
  </div>
</template>

<script>
import { getCheckDetial } from "@/api/balance";
import i18n from "@/i18n";
export default {
  data() {
    return {
      activeName: "1",
      formPage: {
        pageIndex: 1,
        pageSize: 10,
        keyword: "-1"
      },
      balanceList: [],
      isMore: false,
      flag: true
    };
  },
  mounted() {
    if (this.$route.query.langEnv == 2) {
      i18n.global.locale.value = "en";
    } else if (this.$route.query.langEnv == 3) {
      i18n.global.locale.value = "zh";
    }
    this.getList();
    window.addEventListener("scroll", this.handleScroll);
    document.title = this.$t("餘額明細");
  },
  methods: {
    handleChange(index) {
      console.log(index);
      this.formPage.pageIndex = 1;
      this.formPage.keyword = index;
      window.scrollTo(0, 0);
      this.getList();
    },
    async handleScroll(event) {
      const windowHeight = window.innerHeight;
      const documentHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      );
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const hasVerticalScrollbar =
        document.documentElement.scrollHeight >
        document.documentElement.clientHeight;

      if (hasVerticalScrollbar && scrollTop + windowHeight >= documentHeight) {
        this.isMore = true;
        this.formPage.pageIndex += 1;
        await this.getList();
      }
    },
    async scrollPage() {
      const container = this.$refs.container;

      if (
        Math.ceil(container.scrollTop) + container.clientHeight >=
        container.scrollHeight
      ) {
        this.formPage.pageIndex += 1;
        await this.getList();
      }
    },
    // 去重
    duplicates(arr) {
      const map = new Map();
      const newArr = arr.filter(v => !map.has(v.date) && map.set(v.date, 1));
      console.log(newArr);

      return newArr;
    },
    async getList() {
      const res = await getCheckDetial(this.formPage);

      if (res.resultID === 1200) {
        if (this.formPage.pageIndex > 1) {
          if (res.data.length === 0) {
            this.isMore = false;
          }else{

            this.balanceList = [...this.balanceList, ...res.data];
          }
        } else {
          this.balanceList = res.data;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.balancedetial-list {
  padding: 16px;
  padding-top: 50px;
  overflow: auto;
  .balancedetial-date {
    margin: 16px 0;
  }
}
.balance-list-content {
  height: 500px;
  overflow: auto;
}
.balancedetial-item {
  background-color: #fff;
  border-radius: 10px;
}
.balance-item {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f8f8f8;
  .balance-type {
    color: #1a1a1a;
  }
  .balance-date {
    font-size: 12px;
    color: #999999;
  }
  .amount {
    color: #1a1a1a;
    font-weight: bold;
  }
}
.van-tabs-fixed {
  position: fixed;
  width: 100%;
  z-index: 10;
}
.more {
  text-align: center;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 13px;
  color: #999999;
  line-height: 20px;
  padding-bottom: 30px;
}
.loading {
  text-align: center;
  padding-bottom: 30px;

}
</style>
